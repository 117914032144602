import React from 'react'

import Fade from 'react-reveal/Fade'
import {
    FacebookShareButton,
    
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    ViberShareButton,
    FacebookIcon,
    TwitterIcon,
    GooglePlusIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon,
    ViberIcon,
} from 'react-share'
const shareUrl = 'https://maliviki.hu/'
const title = 'Language and Yoga'
const divStyle = {
    margin: '0',
    minWidth: '100%',
}
const Footer = () => (
    <div className="content has-text-centered ">
        <div className="container" style={{ ...divStyle }}>
            <div className="columns footerStyle">
                <div className="column is-4 is-offset-4">
                    <Fade bottom>
                        <div className="Demo__container">
                            <div className="Demo__some-network">
                                <FacebookShareButton
                                    aria-hidden="true"
                                    aria-label="Facebook"
                                    url={shareUrl}
                                    quote={title}
                                    className="Demo__some-network__share-button"
                                >
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                            </div>
                            <div className="Demo__some-network">
                                <TwitterShareButton
                                    aria-hidden="true"
                                    aria-label="Twitter"
                                    url={shareUrl}
                                    title={title}
                                    className="Demo__some-network__share-button"
                                >
                                    <TwitterIcon size={32} round />
                                </TwitterShareButton>
                            </div>
                            <div className="Demo__some-network">
                                <WhatsappShareButton
                                    aria-hidden="true"
                                    aria-label="Whatsapp"
                                    url={shareUrl}
                                    title={title}
                                    className="Demo__some-network__share-button"
                                >
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                            </div>
                            
                            <div className="Demo__some-network">
                                <LinkedinShareButton
                                    aria-hidden="true"
                                    aria-label="Linkedin"
                                    url={shareUrl}
                                    title={title}
                                    className="Demo__some-network__share-button"
                                >
                                    <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                            </div>
                            <div className="Demo__some-network">
                                <EmailShareButton
                                    aria-hidden="true"
                                    aria-label="Email"
                                    url={shareUrl}
                                    subject={title}
                                    body="body"
                                    className="Demo__some-network__share-button"
                                >
                                    <EmailIcon size={32} round />
                                </EmailShareButton>
                            </div>
                            <div className="Demo__some-network">
                                <ViberShareButton
                                    aria-hidden="true"
                                    opt={'aria-label="Viber"'}
                                    url={shareUrl}
                                    title={title}
                                    body="body"
                                    className="Demo__some-network__share-button"
                                >
                                    <ViberIcon size={32} round />
                                </ViberShareButton>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    </div>
)
export default Footer
